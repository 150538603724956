<template>
  <div class="qrcodeView">
      <div class="page-title">{{ $t('pageTitle.qrcode') }}</div>
      <div class="qrcode-wrapper" ref="qrcodeWrapper" :style='{ height: qrcodeHeight }'>
        <div class="qrcode">
          <div class="lblTenantName" v-if="tenantName != null">{{tenantName}}</div>

          <div class="lbl lblStoreSafelyHint section">
            <span class="lblHint">{{ $t('qrcode.lblHint') }}</span><br/>
            {{ $t('qrcode.lblStoreSafelyHint') }}
            </div>
          <div class="section">
            <div class="lblTenantId" v-if="tenantId != null">{{ $t('qrcode.lblTenant') }}<br/><span class="tenantId">{{tenantId}}</span></div>
            <qrcode-vue style="display:none" v-if="connectionInfo != null" :value="connectionInfo" :size="240"></qrcode-vue>
            <img class="link qrImg" ref='qrImg' @click="downloadQrcode"/><br/>
            <span class="actionButton" :title="$t('qrcode.download')" @click="downloadQrcode"><i class="fas fa-file-download"></i>&nbsp;Download</span>&nbsp;&nbsp;
          </div>
          <div class="section">
            <div class="lbl lblConnInfo">{{ $t('qrcode.lblConnInfo') }}</div>
            <div class="connInfo">{{ connectionInfo }}</div>
            <span class="actionButton" :title="$t('qrcode.copy')" @click="copyCiToClipboard($event)"><i class="far fa-copy"></i>&nbsp;Copy</span>
          </div>

          <div class="section magicLink" v-if="magicLink">
            <span class="lblTenantId">Magic Link:</span><br/>
            <a :href="magicLink">{{ magicLink }}</a><br/><br/>
            <span class="lblTenantId">{{ $t('modal.titleMagicLinkPassword') }}:</span> {{ pw4magicLink }}<br/><br/>
            <span class="actionButton" :title="$t('qrcode.copy')" @click="copyLinkToClipboard($event)"><i class="far fa-copy"></i>&nbsp;Copy Link</span>&nbsp;&nbsp;
            <span class="actionButton bgBrown" :title="$t('qrcode.btnCreateMagicLink')" @click="askForMagicLinkPassword()"><i class="fas fa-link"></i>&nbsp;{{$t('qrcode.btnCreateMagicLink')}}</span>
          </div>
        </div>
        <br/>
        <br/>
      </div>
      <PromptModal ref="password4MagicLink" :title="$t('modal.titleMagicLinkPassword')" :text="$t('modal.textMagicLinkPassword')"/>
  </div>
</template>

<script>
/* eslint-disable no-console */
import PromptModal from '@/components/modals/PromptModal.vue';
import QrcodeVue from 'qrcode.vue';
import ServiceHelper from '../helpers/ServiceHelper';
import CryptoHelper from '../helpers/CryptoHelper';

export default {
  name: 'QrcodeView',
  components: {
    QrcodeVue,
    PromptModal,
  },
  props: [],
  async mounted() {
    const storedConnectionInfo = localStorage.getItem('col_ci');
    if (storedConnectionInfo != null) {
      this.connectionInfo = storedConnectionInfo;
      this.tenantId = ServiceHelper.getTenant();
      const resp = await ServiceHelper.loadDataFromBackend('tenant');
      this.tenant = resp.payload.tenant;
      if (this.tenant != null) {
        this.tenantName = CryptoHelper.decrypt(this.tenant.tenantName);
      }
    }
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    this.transferQrCodeToImage();
    this.generateMagicLink(this.generatePW());
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  data() {
    return {
      connectionInfo: null,
      qrcodeHeight: '400px',
      tenantId: null,
      tenantName: null,
      magicLink: null,
      pw4magicLink: null,
      tenant: null,
    };
  },
  methods: {
    resizeHandler() {
      this.qrcodeHeight = `${(window.innerHeight - this.$refs.qrcodeWrapper.offsetTop - 10)}px`;
    },
    copyCiToClipboard() {
      navigator.clipboard.writeText(this.connectionInfo).then(() => {
      }).catch((err) => {
        console.error('Something went wrong', err);
      });
    },
    copyLinkToClipboard() {
      navigator.clipboard.writeText(this.magicLink).then(() => {
      }).catch((err) => {
        console.error('Something went wrong', err);
      });
    },
    transferQrCodeToImage() {
      let canvas = null;
      try {
        // eslint-disable-next-line prefer-destructuring
        canvas = document.getElementsByTagName('canvas')[0];
        const imageData = canvas.toDataURL('image/jpeg', 0.75);
        this.$refs.qrImg.src = imageData;
      } catch (e) {
        setTimeout(this.transferQrCodeToImage, 100);
      }
    },
    async downloadQrcode() {
      const imageData = this.$refs.qrImg.src.replace('data:image/jpeg;base64,', '');
      const blob = new Blob([Buffer.from(imageData, 'base64')], { type: 'image/jpeg' });
      const imageURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `colada_qr_code-${this.tenantId}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async askForMagicLinkPassword() {
      const pw4ML = await this.$refs.password4MagicLink.show(this.generatePW(5));
      if (pw4ML != null && pw4ML.trim().length > 0) {
        // encrypt connectionInfo with password
        this.generateMagicLink(pw4ML);
      }
    },
    generatePW(length = 5) {
      let pw = '';
      const chars = '123456789abcdefghjkmnpqrstuvwxyz';
      while (pw.length < length) {
        const rnd = Math.random() * chars.length;
        pw = `${pw}${chars.substring(rnd, rnd + 1)}`;
      }
      return pw;
    },
    generateMagicLink(pw4ML) {
      const genPW = CryptoHelper.createKeyFromSeed(pw4ML);
      const storedConnectionInfo = localStorage.getItem('col_ci');
      const cryptedCI = encodeURIComponent(CryptoHelper.encrypt(storedConnectionInfo, Buffer.from(genPW, 'base64')));
      this.magicLink = `${process.env.VUE_APP_COLADA_PORTAL_URL}?ci=${cryptedCI}`;
      this.pw4magicLink = pw4ML;
    },
  },
};
</script>
<style scoped>
.qrcode-wrapper {
  overflow-y: auto;
}

.qrcode {
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

.qrImg {
  width: 240px;
  height: 240px;
}

.lbl {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 8px;
}

.lblTenantId {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 32px;
}

.tenantId {
  font-size: 20px;
  color: var(--color_mint);
}

.lblHint {
  font-weight: 600;
  line-height: 32px;
}

.actionButton {
  margin-top: 8px;
}

.lblStoreSafelyHint {
  font-weight: 500;
  color: black;
  background-color: #f4995433;
  font-size: 16px;
}

.connInfo {
  max-width: 800px;
  overflow: hidden;
  overflow-wrap: break-word;
}

.magicLink {
  padding: 8px;
  white-space: normal;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.lblTenantName {
  margin: 16px 0pc;
  font-size: 32px;
}

.section {
  width: calc(100% - 40px);
  border: 1px solid var(--color_orange);
  box-shadow: 1px 1px 8px var(--color_orange);
  margin-bottom: 32px;
  padding: 16px;
}

@media screen and (max-width: 800px) {
  .lblStoreSafelyHint {
    font-size: 14px;
  }
}

</style>
